.content-box {
  padding-bottom: 10px;
}
.content-box .content {
  overflow: hidden;
  padding: 2px;
  font-size: 10px;
  font-weight: bold;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid gray;
  color: #235db3;
  text-align: center;
  background: #eee;
}

.content-box .title {
  /* height:30px; */
  line-height: 15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: block;
  display: block;
  padding: 0px 2px;
  border: 1px solid gray;
  border-bottom: none;
  text-align: center;
}
.content-box.green .title {
  background: green;
  color: white;
}
.content-box.yellow .title {
  background: yellow;
  color: black;
}
.content-box.blue .title {
  background: navy;
  color: white;
}
.content-box.gray .title {
  background: gray;
  color: white;
}

.content-box .content.orange {
  background-color: orange;
  color: black;
}

.content-box .content.red {
  background-color: red;
  color: white;
}

.box-compare-selection.active .title {
  background: green;
  color: white;
}

.content-box-subtitle {
  border-top: 1px solid #000;
  padding: 1px 0 0;
  display: block;
}
