.bet-maker {
  width: 1010px;
  .cj343x07-0-0-beta-47 {
    @layer rdg.Cell {
      &[aria-selected="true"] {
        outline: none !important;
        outline-offset: 0px !important;
      }
    }
  }

  [role="columnheader"] {
    color: #000;
    text-align: center;
  }

  .left {
    text-align: left;
  }
}
@media (max-width: 768px) {
  .bet-maker {
    width: 100%; /* Adjust the width for mobile devices */
  }
}

.fav1RowClass {
  .rdg-cell {
    background-color: #ff0;
    color: #000;
  }

  &:hover .rdg-cell {
    background-color: #ffae00;
  }
}

.fav2RowClass {
  .rdg-cell {
    background-color: #ff0;
    color: #000;
  }

  &:hover .rdg-cell {
    background-color: #ffae00;
  }
}

.favOtherRowClass {
  .rdg-cell {
    background-color: navy;
    color: #fff;
  }

  &:hover .rdg-cell {
    background-color: rgb(0, 41, 128);
  }
}

.bf-overlay {
  position: absolute;
  width: 950px;
  height: 300px;
  top: 295px;
  left: 105px;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 400;
  /* cursor: pointer;*/
}

.bf-overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: red;
  transform: translate(-50%, -50%) rotate(-20deg);
  -ms-transform: translate(-50%, -50%) rotate(-20deg);
  text-transform: uppercase;
  font-weight: 800;
}
