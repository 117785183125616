.multiStateBtn-empty {
  width: 75px;
  height: 22px;
  border: 1px #fff dashed;
  margin: 5px;
}

.multiStateBtn {
  font-size: 0.8rem;
  width: 85px;
  height: 22px;
  /* border: 1px solid #f2f2f2; */
  border-radius: 5px;
  /* box-shadow: 3px 3px 2px #a9a9a9; */
  background-color: rgb(7, 71, 166);
  color: rgb(222, 235, 255);
  /* border: rgb(222, 235, 255) 2px solid; */
  border: none;
  cursor: pointer;
  transition: all 0.2s;
}

.multiStateBtn:hover {
  /* border: rgb(7, 71, 166) 2px solid; */
  color: rgb(7, 71, 166);
  background-color: rgb(222, 235, 255);
}
.multiStateBtn:focus {
  outline: none;
}

.multiStateBtnContent {
  display: flex;
  direction: row;
  margin: 0 5px 0 5px;
  gap: 10px;
  padding: 0 0 0 5px;
}

.multiStateBtnContent > svg {
  margin-top: 3px;
}
