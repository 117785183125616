.parent {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.parent .header {
  background: #f0f0f0;
  padding: 2px;
  width: 100%;
  height: 15px;
  text-align: center;
  border: 1px black dotted;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.parent .header .dragger {
  cursor: move;
  width: 99%;
  /* height: 15px; */
}

.parent .header .remove {
  /* position: absolute; */
  right: 10px;
  cursor: pointer;
  width: 1%;
  /* height: 10px; */
  margin: -9px 0 0;
}

.parent .body {
  width: 100%;
  height: 95%;
  overflow: auto;
  margin: -3px 0 0;
}

.parent .body > div {
  width: 100%;
  height: 100%;
}

.react-grid-item:not(.react-grid-placeholder) {
  background: #ccc;
  border: 1px solid black;
}
