.sbb-table {
  .cj343x07-0-0-beta-47 {
    @layer rdg.Cell {
      &[aria-selected="true"] {
        outline: none !important;
        outline-offset: 0px !important;
      }
    }
  }

  [role="columnheader"] {
    color: #000;
    text-align: center;
  }

  .left {
    text-align: left;
  }
}

.sbbHighlightedRowClass {
  .rdg-cell {
    background-color: #ff0;
    color: #000;
    border: #000;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-top-style: solid;
  }

  .rdg-cell:first-child {
    border-left-width: 1px;
    border-left-style: solid;
  }

  .rdg-cell:last-child {
    border-right-width: 1px;
    border-right-style: solid;
  }

  &:hover .rdg-cell {
    background-color: #ffae00;
  }

  .sbbBSPFilter {
    color: #000;
    border: #000 4px double;
  }
}

.sbbRowClass {
  .rdg-cell {
    border-bottom-style: dashed;
    border-bottom-width: 1px;
  }

  &:hover .rdg-cell {
    /* background-color: #0015ff; */
  }
}
